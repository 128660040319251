/* eslint-disable max-len,react/no-danger */
import React from "react";
import { PageHeader, Seo, Footer } from "components/common";
import PropTypes from "prop-types";
import { withStyles } from "@material-ui/core";
import styles from "components/commonStyles";
import { siteUrl } from "configs/constants";
import { Link } from "gatsby";

function DotNet({ classes }) {
  return (
    <>
      <Seo title=".Net" url={`${siteUrl}/dot-net`} />
      <PageHeader />

      <div className={classes.content}>
        <p style={{ marginBottom: 20 }}>
          Integration with Microsoft .NET technology, C#, Visual Basic and other
          .NET languages.
        </p>
        <p style={{ marginBottom: 20 }}>
          We would be happy if you can come to us with a sources of WCF based C#
          library working with our{" "}
          <Link to="/developers/json-rest-api-guidelines/">
            <span className={classes.textColor}>JSON-REST</span>
          </Link>{" "}
          server accompanied with ms-build file with at least one single entity
          (e.g. /{"v15/{sellerId}/products"}
          ), so we can take over the library development and provide
          full-featured library working with all the entities described at our{" "}
          Reference .
        </p>
        <p style={{ marginBottom: 20 }}>
          Meanwhile we have plans to use Swagger to create .NET based API. This
          issue is in a pipeline but the delivery date is unknown yet.
        </p>
        <p>
          Our intention is to publish at{" "}
          <span className={classes.textColor}>
            <a
              href="https://www.nuget.org/"
              target="_blank"
              rel="noopener noreferrer"
            >
              nuget.org
            </a>
          </span>{" "}
          the Assembly, Documentation reference, Source code and other necessary
          materials for your later usage.
        </p>
      </div>
      <Footer />
    </>
  );
}

DotNet.propTypes = {
  classes: PropTypes.shape({
    content: PropTypes.string.isRequired,
    text: PropTypes.string.isRequired,
    textColor: PropTypes.string.isRequired
  }).isRequired
};

export default withStyles(styles, { withTheme: true })(DotNet);
